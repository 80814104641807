.moon-phase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 1rem;
  background-color: rgba(26, 35, 89, 0.3);
}

@keyframes subtle-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.moon-phase-container .text-6xl {
  animation: subtle-float 4s ease-in-out infinite;
}
